var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"error-view",attrs:{"id":"errorContainer"}},[_c('div',{staticClass:"heading",attrs:{"id":"heading"},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('img',{staticClass:"logo margin-x",attrs:{"src":require("@/assets/logo_with_name.svg"),"alt":"Selia"}})]),_vm._m(0),_c('div',{staticClass:"error-view-options"},[_c('div',{staticClass:"error-view-options-content padding-x"},[_vm._m(1),_c('div',{staticClass:"heading-big margin-y",domProps:{"textContent":_vm._s(_vm.$translations['error']['error-view'].title)}}),_c('span',{staticClass:"body",domProps:{"textContent":_vm._s(_vm.$translations['error']['error-view'].description)}}),_c('span',{staticClass:"body",domProps:{"textContent":_vm._s(
          _vm.$translations['error']['error-view'].subtitle[
            _vm.isAuthenticated ? 0 : 1
          ]
        )}}),_c('div',{staticClass:"error-view-options-content-wrapper"},_vm._l((_vm.options),function(option,index){return _c('MediumCard',{key:index,attrs:{"illustration":option.img,"title":option.title,"bottomLine":option.text,"showAction":option.showAction},on:{"clicked":option.action}})}),1),_c('div',[(!_vm.isAuthenticated)?_c('span',{staticClass:"content-title",domProps:{"textContent":_vm._s(_vm.$translations['error']['error-view']['before-action'])}}):_vm._e(),_c('span',{staticClass:"link content-title text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(
            _vm.$translations['error']['error-view'].action[
              _vm.isAuthenticated ? 0 : 1
            ]
          )},on:{"click":function($event){return _vm.action()}}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"error-view-splash"},[_c('img',{staticClass:"error-view-splash-img",attrs:{"src":require("@/assets/error_view.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"error-view-options-logo"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo_with_name.svg"),"alt":"Selia"}})])
}]

export { render, staticRenderFns }