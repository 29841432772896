<template>
  <div class="error-view" id="errorContainer">
    <!-- header -->
    <div id="heading" class="heading" @click="$router.push({ name: 'Home' })">
      <img
        class="logo margin-x"
        src="@/assets/logo_with_name.svg"
        alt="Selia"
      />
    </div>

    <!-- splash area -->
    <div class="error-view-splash">
      <img class="error-view-splash-img" src="@/assets/error_view.svg" />
    </div>

    <!-- Options -->
    <div class="error-view-options">
      <div class="error-view-options-content padding-x">
        <div class="error-view-options-logo">
          <img class="logo" src="@/assets/logo_with_name.svg" alt="Selia" />
        </div>
        <div
          class="heading-big margin-y"
          v-text="$translations['error']['error-view'].title"
        />
        <span
          class="body"
          v-text="$translations['error']['error-view'].description"
        />
        <span
          class="body"
          v-text="
            $translations['error']['error-view'].subtitle[
              isAuthenticated ? 0 : 1
            ]
          "
        />

        <div class="error-view-options-content-wrapper">
          <MediumCard
            v-for="(option, index) in options"
            :key="index"
            :illustration="option.img"
            :title="option.title"
            :bottomLine="option.text"
            :showAction="option.showAction"
            @clicked="option.action"
          />
        </div>

        <div>
          <span
            class="content-title"
            v-if="!isAuthenticated"
            v-text="$translations['error']['error-view']['before-action']"
          />
          <span
            @click="action()"
            class="link content-title text-purple cursor-pointer"
            v-html="
              $translations['error']['error-view'].action[
                isAuthenticated ? 0 : 1
              ]
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MediumCard } from '@seliaco/red-panda'

export default {
  components: { MediumCard },
  data () {
    return {
      windowWidth: 0,
      options: []
    }
  },
  computed: {
    isAuthenticated () {
      return Boolean(
        localStorage.getItem(
          `selia_${process.env.VUE_APP_AUTH_MODE.toLowerCase()}_access_token`
        )
      )
    }
  },
  mounted () {
    this.getOptions()
  },
  methods: {
    action () {
      if (this.isAuthenticated) {
        this.$router.push({ name: 'Home' })
      } else {
        this.$router.push({
          name: 'SignIn',
          query: {
            next: this.$route.query.next
          }
        })
      }
    },
    getOptions () {
      if (this.isAuthenticated) {
        this.options = [
          {
            img: 'matching_agent',
            title:
              this.$translations['on-boarding'].slider['multiple-choice']
                .choices['choice-1'].title,
            text: this.$translations['on-boarding'].slider['multiple-choice']
              .choices['choice-1'].text,
            showAction: true,
            action: async () => {
              this.$router.push({
                name: 'QuestionnaireWelcome',
                query: {
                  back: this.$route.fullPath
                }
              })
            }
          },
          {
            img: 'women_thinking_illustration',
            title:
              this.$translations['on-boarding'].slider['multiple-choice']
                .choices['choice-2'].title,
            text: this.$translations['on-boarding'].slider['multiple-choice']
              .choices['choice-2'].text,
            showAction: true,
            action: async () => {
              this.$router.push({
                name: 'RecommendedCounselling',
                query: {
                  back: '/',
                  init: true
                }
              })
            }
          },
          {
            img: 'search_all_illustration',
            title:
              this.$translations['on-boarding'].slider['multiple-choice']
                .choices['choice-3'].title,
            text: this.$translations['on-boarding'].slider['multiple-choice']
              .choices['choice-3'].text,
            showAction: true,
            action: async () => {
              this.$router.push({
                name: 'Search',
                query: {
                  back: this.$route.fullPath
                }
              })
            }
          }
        ]
      } else {
        this.options = [
          {
            img: 'logo',
            title: this.$translations.error['error-view'].card.title,
            text: this.$translations.error['error-view'].card.text,
            showAction: true,
            action: async () => {
              this.$router.push({
                name: 'SignUp',
                query: {
                  next: this.$route.fullPath
                }
              })
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$footerSize: calc(80px + #{env(safe-area-inset-bottom)})
$contentSize: calc(100vh - #{env(safe-area-inset-top)})
$contentSizeWithAction: calc(#{$contentSize} - #{$footerSize})

.error-view
  background: var(--gray-5)
  height: $contentSizeWithAction
  &-splash
    padding-top: calc(52px + env(safe-area-inset-top))
    min-height: 343px
    &-img
      max-height: 500px
      width: 100%
      object-fit: cover
  &-options
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    margin-top: -3px
    padding: 40px 16px
    gap: 24px
    background: var(--white)
    &-content
      max-width: 480px
      display: flex
      flex-direction: column
      align-items: center
      width: 100%
      gap: 16px
      &-wrapper
        width: 100%
    &-logo
      display: none
  .heading
    height: calc(52px + env(safe-area-inset-top))
    display: flex
    width: 100%
    justify-items: start
    align-items: flex-end
    border-bottom: 1px solid var(--gray-20)
    background: var(--white)
    left: 0
    right: 0
    position: fixed
    transition: 0.3s
    padding-bottom: 14px

@media (min-width: 780px)
  .error-view
    display: grid
    grid-template-columns: repeat(2, 1fr)
    align-items: center
    height: 100vh
    &-splash
      &-img
        max-height: none
    &-options
      margin-top: 0
      height: 100%
      &-content
        margin: auto 0
      &-logo
        display: block
    .heading
      display: none
</style>
